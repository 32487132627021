import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useResponsiveValues } from "../../../../utils/hooks";
import { XEL_COLORS } from "../../../../../constant";


export default function PlanName() {

    const plan = useSelector(state => state.planname)
    return <Grid container style={{ marginBottom: '1.5%', justifyContent: 'center' }}>
        <Grid item xs={12}
            md={4}
            lg={6}
            style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", background: XEL_COLORS.orangeGradient }}>
            <Typography
                style={{ color: '#FFF', fontSize: useResponsiveValues(18, 14, 10) }}>
                Plan seleccionado:
            </Typography>
            <Typography style={{
                fontSize: useResponsiveValues(25, 20, 15),
                color: '#FFF',
                fontWeight: '700',
            }}
            >
                {
                    plan.name
                }
            </Typography>
        </Grid>
    </Grid>
}
