import { Box, Button, Card, CardContent, CardHeader, Paper, Typography, makeStyles } from "@material-ui/core"
import { useHistory } from 'react-router-dom'
import { Facebook } from "../Icons/Facebook"
import { Whatsapp } from "../Icons/Whatsapp"
import { Messenger } from "../Icons/Messenger"
import { X } from "../Icons/X"
import { Snapchat } from "../Icons/Snapchat"
import { Telegram } from "../Icons/Telegram"
import { ASSETS_URL } from "../../../constant"
import { PhoneCall } from "../Icons/PhoneCall"
import { Messages } from "../Icons/Messages"

const useStyles = makeStyles({
    card: {
        background: 'linear-gradient(90deg, #B627FF 0%, #8A0EC9 46.35%, #412C5F 100%)',
        maxWidth: 262,
        minWidth: 262,
        margin: 10
    },
    header: {
        position: 'relative',
    },
    title: {
        position: 'absolute',
        top: 20,
        right: 0,
        backgroundColor: '#FE8D28',
        minWidth: '50%',
        padding: '0 5px',
    },
    name: {
        color: '#FFF',
        fontSize: '20px',
        fontWeight: 'bold',
    },
    content: {
        padding: '30px 0 0 0',
        '&:last-child': {
            paddingBottom: 0
        }
    },
    planName: {
        fontSize: '32px',
        fontWeight: 'bold',
        color: '#FFF',
        display: 'flex',
        alignItems: 'top',
        justifyContent: 'center',
        '& span': {
            fontSize: '7px',
            color: '#AEAEAE',
        }
    },
    subtitle: {
        color: '#FFF',
        fontSize: '10px',
    },
    details: {
        padding: '12px 0px',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        '& .services': {
            display: 'flex',
            justifyContent: 'space-around',
            '& .MuiTypography-body1': {
                fontSize: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                color: '#5A3471'
            }
        },
        '& .altan': {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
            '& .MuiTypography-body1': {
                fontSize: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                background: 'linear-gradient(90deg, #B627FF 0%, #8A0EC9 46.35%, #412C5F 100%)',
                color: 'transparent',
                backgroundClip: 'text'
            },
            height: '18px'
        },
        '& .price': {
            background: '#FE8D28',

            '& .MuiTypography-body1': {
                fontSize: '40px',
                color: '#FFF',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& span': {
                    fontSize: '12px',
                    color: '#FFF',
                    paddingLeft: '5px'
                }
            }

        },
        '& .folio .MuiTypography-root': {
            fontSize: '10px',
            color: '#AC21F3',
            marginTop: '10px',
            textAlign: 'center'
        },
        '& .button': {
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'center',
            '& .MuiButtonBase-root': {
                background: 'linear-gradient(90deg, #B627FF 0%, #8A0EC9 46.35%, #412C5F 100%)',
                color: '#FFF',
                fontWeight: 'bold',
                fontSize: '15px',
                padding: '5px 20px',
                borderRadius: '100px',

                '&:hover': {
                    background: '#FE8D28',
                    color: '#FFF',
                }
            }
        },
        '& .flags': {
            display: 'flex',
            justifyContent: 'center',
            '& img': {
                width: '40px',
                margin: '0 5px',
                height: 'auto'
            },
            marginTop: '10px',
            marginBottom: '20px'
        }
    },
    rsss: {
        margin: '10px 0',
        height: '40px',
        '& .icons': {
            display: 'flex',
            justifyContent: 'center',
        },
        '& .text': {
            fontSize: '10px',
            fontWeight: 'bold',
            color: '#FFF',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'top',
            justifyContent: 'center',
            '& span': {
                fontSize: '7px',
                color: '#AEAEAE',
                marginLeft: '2px'
            }
        }
    }

})

export const Plan = ({ planData }) => {
    const { mb_title, name, hotspot, min, sms, price, folio, type, id, period, gb } = planData;
    const styles = useStyles()

    const history = useHistory();
    return <Card classes={{
        root: styles.card
    }} >
        <CardHeader className={styles.header} title={<Box className={styles.title}  >
            <Typography className={styles.name} align="center"  >
                {name.split('*')[0]}
            </Typography>
        </Box>} />
        <CardContent className={styles.content}>
            <Box>
                <Typography align="center" variant="h2" className={styles.planName}  >
                    {mb_title || `${(Number(gb) * 1000).toLocaleString('es-MX')} MB` || '0 MB'} <span>(1)</span>
                </Typography>
                <Typography align="center" className={styles.subtitle}>
                    Navega {hotspot ? 'y comparte' : ''}
                </Typography>
                <Box className={styles.rsss}>
                    {
                        planData.rrss && <><Box className="icons">
                            <Facebook />
                            <Whatsapp />
                            <Messenger />
                            <X />
                            <Snapchat />
                            <Telegram />
                        </Box>
                            <Typography className="text">
                                Redes sociales ilimitadas <span>(2)</span>
                            </Typography></>
                    }
                </Box>
                <Paper className={styles.details}>
                    <Box className="services">
                        <Typography>
                            <PhoneCall />
                            {
                                Number(min).toLocaleString('es-MX')
                            } Minutos
                        </Typography>
                        <Typography>
                            <Messages />
                            {
                                Number(sms).toLocaleString('es-MX')
                            } mensajes
                        </Typography>
                    </Box>
                    <Box className="altan">
                        {
                            planData.altan_ilimitado && <Typography>
                                Red Altan ilimitada
                            </Typography>
                        }
                    </Box>
                    <Box className="flags">
                        <img src={`${ASSETS_URL}/inxel/media/mex.png`} />
                        <img src={`${ASSETS_URL}/inxel/media/usa.png`} />
                        <img src={`${ASSETS_URL}/inxel/media/canada.png`} />
                    </Box>
                    <Box className="price">
                        <Typography>
                            ${price} <span>{period}</span>
                        </Typography>
                    </Box>
                    <Box className="button">

                        <Button color="primary"
                            onClick={() => history.push(`comprar/${type}/${id}`)}
                        >
                            Comprar
                        </Button>
                    </Box>
                    <Box className="folio">
                        <Typography>
                            Folio: {folio}
                        </Typography>
                    </Box>
                </Paper>
            </Box>
        </CardContent>

    </Card>
}