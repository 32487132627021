import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useResponsiveValues } from '../../../utils/hooks'
import { useSelector } from 'react-redux'
import { XEL_COLORS } from '../../../../constant'
import { Box, Divider, Grid } from '@material-ui/core'

export default () => {
    const flexDirection = useResponsiveValues(
        'row',
        'column-reverse',
        'column-reverse'
    )
    const flexDirection2 = useResponsiveValues('row', 'column', 'column')
    const spaceing = useResponsiveValues('50px', '30px', '10px')
    const textAlign = useResponsiveValues('left', 'center', 'center')

    const data = useSelector((state) => state.productResumeData)
    const benefits = []

    switch (data.type) {
        case 'preplan':
        case 'plan':
            benefits[0] = {
                title: data?.mb_title.toLowerCase().split('mb')[0].trim() + ' MB',
                sub: `${data.gb
                    } GB en roaming internacional En Estados Unidos y Canadá`,
            }
            benefits[1] = {
                title: `${data.segunda_desc.split(' ')[0]} Min`,
                sub: `${data.segunda_real_desc.split(' ')[0]} min en Roaming
			    Estados Unidos y Canadá`,
            }
            benefits[2] = {
                title: `${data.sms} SMS`,
                sub: `${data.tercera_real_desc.split(' ')[0]
                    } min en Roaming Estados Unidos y Canadá`,
            }
            break
        case 'mifi':
            benefits[0] = {
                title: data.gb + 'GB',
                sub: data.primera_desc.split(' ').slice(2).join(' '),
            }
            benefits[1] = { title: 'Conexión Portátil', sub: data.segunda_desc }
            benefits[2] = {
                title: `${data.segunda_real_desc.split(' ')[3]} Dispositivos`,
                sub: data.segunda_real_desc,
            }
            break
        case 'hbb':
            benefits[0] = {
                title: data.gb + 'GB',
                sub: data.primera_desc.split(' ').slice(2).join(' '),
            }
            benefits[1] = {
                title: data.segunda_desc,
                sub: data.segunda_real_desc,
            }
            benefits[2] = {
                title: data.tercera_desc.split(' ').slice(2).join(' '),
                sub: data.tercera_desc + ' ' + data.tercera_real_desc,
            }
    }

    if (data.stepperTexts) {
        data.toptext = data.stepperTexts.value.split('-')[0]
        data.bottomtext = data.stepperTexts.value.split('-')[1]
        data.pricetext = data.stepperTexts.value.split('-')[2]
    }

    return (
        <div
            style={{
                width: '100%',
                boxShadow: '2px 6px 13px -4px rgba(0,0,0,0.49)',
                padding: spaceing,
                margin: spaceing,
                marginTop: 0,
                display: 'flex',
                flexDirection,
            }}
        >
            <div
                style={{
                    flex: 1,
                    textAlign,
                }}
            >
                <Typography variant={'h5'} color={'secondary'}>
                    {data.toptext}
                </Typography>
                <Grid container style={{
                    borderBottom:useResponsiveValues('none', '1px solid '+XEL_COLORS.secondary, '1px solid '+XEL_COLORS.secondary),
                    borderTop:useResponsiveValues('none', '1px solid '+XEL_COLORS.secondary, '1px solid '+XEL_COLORS.secondary),
                }}>
                    {
                        benefits.map((benefit, index) => <Grid item key={index} xs={12} style={{ margin: 7 }}
                        >
                            <Grid container style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Grid item xs={12} md={3}>
                                    <Typography variant={'h4'} style={{
                                        background: XEL_COLORS.orangeGradient,
                                        backgroundClip: 'text',
                                        color: 'transparent',
                                    }} >
                                        {benefit.title}

                                    </Typography>

                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Typography
                                        variant={'body'}
                                        style={{
                                            paddingTop: 10,
                                            marginLeft: 20,
                                            fontSize: '14px',
                                            textAlign: 'center',
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        {benefit.sub}
                                    </Typography>
                                    {
                                        data.rrss && <Typography style={{
                                            textAlign: 'center',
                                            fontSize: '14px',
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>
                                            Redes Sociales ilimitadas
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '10px', display: useResponsiveValues('inherit', 'none', 'none') }}>
                                <Divider style={{ width: '100%', background: XEL_COLORS.orangeGradient }} />
                            </Grid>
                        </Grid>)
                    }
                </Grid>
                <Typography
                    variant={'h5'}
                    color={'primary'}
                    style={{ textAlign: 'center' }}
                >
                    {data.bottomtext}
                </Typography>
            </div>

            <div
                style={{
                    padding: '20px 40px',
                    margin: 10,
                    paddingTop: 63,
                }}
            >
                <Typography
                    variant={'h1'}
                    style={{ textAlign: 'center', background: XEL_COLORS.purpleGradient, backgroundClip: 'text', color: 'transparent' }}
                >
                    ${data.price}
                </Typography>
                <Typography
                    color={'primary'}
                    style={{
                        textAlign: 'center',
                        display: 'display',
                        justifyContent: 'center',
                        width: '100%',
                        font: 'bolder',
                        maxWidth: 240,
                        textAlign: 'center', background: XEL_COLORS.purpleGradient, backgroundClip: 'text', color: 'transparent'
                    }}
                >
                    {data.pricetext ? data.pricetext : data.period}
                </Typography>
            </div>
        </div>
    )
}
