
export const XEL_COLORS = {
    primary_dark: '#412C5F',
    primary: '#5A3571',
    primary2: '#793180',
    primary_shiny: '#A418C5',
    primary_light: '#b2a1bd',
    secondary: '#FF8A21',
    secondary_light: '#f9ae39',
    secondary_dark: '#be681a',
    lila: '#ddd8e4',
    purpleGradient:'linear-gradient(90deg, #B627FF 0%, #8A0EC9 46.35%, #412C5F 100%)',
    orangeGradient:'linear-gradient(90deg, #DB6C06 0%, #FE8518 46.35%, #FBA04E 100%)'
}

export const XEL_FONT = {
    name: `'Montserrat', sans-serif`,
    light_weight: '300',
    regular_weight: '400',
    semi_bold_weight: '600',
    bold_weight: '700',
}

export const OPENPAY = {
    PRODUCTION_ID: 'msot6vrhy0qdhuqbpqnp',
    PRODUCTION_API_KEY: 'pk_06fc6aeb78834947a59885bacda18e50',
    SANDBOX_ID: 'msyrfhdmmzbm9qiuhmso',
    SANDBOX_API_KEY: 'pk_4b8eadee297548f082aa7ca600cecccd'
}

export const Mifi = () => {

}


export const PromosPlan = () => {

    //const [planPrice,setPlanPrice] = useState('');
    let fecha = new Date();
    fecha = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate());
    const finBuen = new Date(2021, 1, 28);
    const validBuenFin = () => {
        if (fecha < finBuen) {
            return true;
        }
        if (fecha.getTime() === finBuen.getTime()) {
            return true;
        }
    }
    const withPromo = () => {

        if (fecha < finBuen) {
            return true;
        }
        if (fecha.getTime() === finBuen.getTime()) {
            return true;
        }
        return false;
    }


    const ACTIVATION = {
        id: 99,
        price: 50
    }
    const XEL5p = {
        name: 'Xel 5+',
        price: 129,
        price_original: 129,
        id: 39846,
        id_original: 39846,
        id_no_garante: 69,
        months: 0,
    }

    const XEL40 = {
        name: 'Xel 40',
        price: 229,
        price_original: 229,
        id: 39848,
        id_original: 39848,
        id_no_garante: 70,
        // id_promo: validBuenFin() ? 104 : 113,
        months: 0
    }
    const XEL40p = {
        name: 'Xel 40+',
        price: 299,
        price_original: 299,
        id: 114,
        id_original: 82,
        id_no_garante: 114,
        months: 0,
        // id_promo:validBuenFin() ? 104 : 113,
    }

    const XEL100 = {
        name: 'Xel 100+',
        price: 499,
        price_original: 499,
        id: 126,
        id_original: 126,
        id_no_garante: 126,
        months: 0,
        // id_promo:validBuenFin() ? 104 : 113,
    }
    const CTXEL5 = {
        name: "CT XEL 5+",
        price: 129,
        price_original: 129,
        id: 65047,
        id_original: 65047,
        id_no_garante: 65047,
        months: 0
    }
    const CTXEL40 = {
        name: "CT XEL 40",
        price: 221,
        price_original: 221,
        id: 65049,
        id_original: 65049,
        id_no_garante: 65049,
        months: 0
    }
    const CTXEL40PLUS = {
        name: "CT XEL 40+",
        price: 313,
        price_original: 313,
        id: 65048,
        id_original: 65048,
        id_no_garante: 65048,
        months: 0
    }
    const s = [
        {
            "id": "65047",
            "name": "CT XEL 5+",
            "hotspot": true,
            "primera_desc": "5GB para navegar, compartir y Servicio de Telemedicina",
            "segunda_desc": "1500 Minutos",
            "segunda_real_desc": "750 Minutos en roaming internacional",
            "tercera_desc": "500 Mensajes",
            "tercera_real_desc": "250 Mensajes en roaming internacional",
            "price": "129",
            "period": "al mes",
            "politica": null,
            "folio": "Sin folio",
            "promos": {},
            "noSocial": true
        },
        {
            "id": "65049",
            "name": "CT XEL 40",
            "hotspot": false,
            "primera_desc": "40GB para navegar, redes sociales (*) y Servicio de Telemedicina",
            "segunda_desc": "1500 Minutos",
            "segunda_real_desc": "750 Minutos en roaming internacional",
            "tercera_desc": "500 Mensajes",
            "tercera_real_desc": "250 Mensajes en roaming internacional",
            "price": "221",
            "period": "al mes",
            "politica": true,
            "folio": "Sin folio",
            "promos": {},
            "noSocial": true
        }, {
            "id": "65048",
            "name": "CT XEL 40+",
            "hotspot": true,
            "primera_desc": "40GB para navegar, redes sociales (*) y Servicio de Telemedicina",
            "segunda_desc": "1500 Minutos",
            "segunda_real_desc": "750 Minutos en roaming internacional",
            "tercera_desc": "500 Mensajes",
            "tercera_real_desc": "250 Mensajes en roaming internacional",
            "price": "313",
            "period": "al mes",
            "politica": true,
            "folio": "Sin folio",
            "promos": {},
            "noSocial": true
        }]

    const getPlan = id => {

        switch (parseInt(id)) {
            case  112:
                return XEL5p;
            case 113:
                return XEL40;
            case 82:
                return XEL40p;
            case 126:
                return XEL100;
            case 65047:
                return CTXEL5;

            case CTXEL40.id:
                return CTXEL40;
            case CTXEL40PLUS.id:
                return CTXEL40PLUS;
            default:
                return false;
        }

    }
    const getName = id => {
        switch (parseInt(id)) {
            case  112:
                return "Xel 5+";
            case 113:
                return 'Xel 40(*)';
            case 82:
                return 'Xel 40+(*)';
            case 126:
                return 'Xel 100+(*)';
            case CTXEL5.id:
                return CTXEL5.name;
            case CTXEL40.id:
                return CTXEL40.name;
            case CTXEL40PLUS.id:
                return CTXEL40PLUS.name;
            default:
                return false;
        }

    }
    const Months = validBuenFin() ? 6 : 0


    return {XEL5p, XEL40, getName, getPlan, Months, ACTIVATION, validBuenFin, withPromo}
}


export const API = 'https://inxel.mx/api/xel/v1';
export const APIHOST = process.env.NODE_ENV === 'production' ? 'https://api.inxel.mx' : 'http://localhost:3000';
export const ASSETS_URL = 'https://abs-public.s3.us-west-1.amazonaws.com'
