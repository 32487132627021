import React, {useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {Grid, useMediaQuery} from '@material-ui/core';

import {useHistory} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import './style.css';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../../actions';
import {XEL_COLORS, XEL_FONT} from "../../../constant";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Cookie from 'js-cookie';
import {XelIconAvatar, XelIconCard} from "../../../assets/logos/nav/svg";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        position: 'fixed',
        zIndex: '10000',
        width: '100%',
        transition: 'height .6s easy-in-out',
        top: '0',
        backgroundColor: 'transparent'

    },
    menuButton: {
        marginRight: theme.spacing(2),
        borderRadius: '10px',
        position: 'fixed',
        backgroundColor: '#000000',
        top: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        zIndex: '5',
        width: '130px',
        height: '90px',
        '&:hover': {
            backgroundColor: '#000000',
            boxShadow: 'none',
        },
    },
    title: {
        flexGrow: 1,
    },
    button_navup_desktop: {
        border: '1px solid #593571',
        margin: '0 5px',
        fontSize: '11px',
        color: '#593571'
    },
    button_navdown_desktop: {
        marginRight: '15px',
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#fefefe'
    },
    first_nav: {
        backgroundImage: 'gradient(linear,left top,right top,color-stop(50%,#F28A30),to(#593571))'
    },

    second_nav: {
        backgroundColor: 'transparent',
        //backgroundImage: 'linear-gradient(to bottom bottom, rgb(0, 0, 0),rgba(255,0,0,0))',
        //  backgroundImage: 'gradient(linear,left top,right top,color-stop(50%,#593571),to(#F28A30))'
    },
    logoDown: {
        transition: 'all 1s ease-in-out',
        animation: 'growDown .6s linear'
    }
    ,
    logoUp: {
        transition: 'all 1s ease-in-out',
        animation: 'growUp .6s linear'
    }
}));

const useStylePagButtons = makeStyles({
    pageButton: {
        border: '2px solid #3f2550',
        marginLeft: '10px'
    },
    active: {
        border: '2px solid #3f2550',
        marginLeft: '10px',
        backgroundColor: '#3f2550'
    },
    label: {
        textTransform: "capitalize"
    }
});

const XelNavButton = withStyles({
    root: {
        height: 40,
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        color: XEL_COLORS.primary,
        fontSize: 18,
    },
    label: {
        textTransform: "capitalize"
    },

})(Button);
const XelRoundedNavButton = withStyles({
    root: {
        margin: 2,
        height: 40,
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        color: XEL_COLORS.primary,
        fontSize: 18,
        //    border: '1px solid ' + XEL_COLORS.primary,
    },
    label: {
        textTransform: 'none'
    },

})(Button);


export default function NavBar(props) {
    let history = useHistory();

    const {secondaryLogo, hide} = props;

    const classes = useStyles();
    const dispatch = useDispatch();
    const login = useSelector(state => state.login);
    const [anchorElMov, setAnchorElMov] = React.useState(null);
    const openMov = Boolean(anchorElMov);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [anchorElE, setAnchorElE] = React.useState(null);
    const openE = Boolean(anchorElE);
    const [anchorElES, setAnchorElES] = React.useState(null);
    const openES = Boolean(anchorElES);
    const [anchorElogin, setAnchorElogin] = React.useState(null);
    const openLogin = Boolean(anchorElogin);
    const MaxTablet = useMediaQuery('(min-width:769px)');
    useEffect(() => {
        let navbar = document.getElementById('app-bar');
        dispatch(Actions.SET_HEIGHT_NAVBAR(navbar.getBoundingClientRect().height));
        navbar.addEventListener('resize', () => {
            dispatch(Actions.SET_HEIGHT_NAVBAR(navbar.getBoundingClientRect().height));
        })
    }, []);

    const handleMenuMov = (event) => {
        setAnchorElMov(event.currentTarget);
    };

    const handleCloseMov = () => {
        setAnchorElMov(null);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    const handleCloseE = () => {
        setAnchorElE(null);
    };

    const handleMenuES = (event) => {
        setAnchorElES(event.currentTarget);
    };

    const handleCloseES = () => {
        setAnchorElES(null);
    };

    const handleMenuLogin = (event) => {
        setAnchorElogin(event.currentTarget);
    };

    const handleCloseLogin = () => {
        setAnchorElogin(null);
    };
    const sendToEmpresas = () => {
        history.push('/empresas');
    }
    const sendToHome = () => {
        if (history.location.pathname === '/') {
            return;
        }
        window.history.go(-1)
        return;
    }
    const sendToAlianzas = () => {
        history.push('/alianzas');
    }
    const sendToPrepago = () => {
        history.push('/personas/prepago');
    }


    const sendToPago = () => {
        history.push('/recarga');
        handleClose();
    }


    const DeskMenu = (<Grid container>
        <Grid item xs={12}>
            <Grid container style={{display: 'flex', height: '70px'}} justify={'space-between'}>
                {
                    hide ? <Grid item style={{
                            height: '100%',
                            display: "flex",
                            width: 'fit-content',
                            alignItems: "center"
                        }}/> :
                        <Grid item
                              style={{height: '100%', display: "flex", width: 'fit-content', alignItems: "center"}}>

                            <XelNavButton
                                //className={path === '/personas' ? styles.active : styles.pageButton}
                                onClick={handleMenu}>
                                XelPlanes
                            </XelNavButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                                style={{top: 50}}
                            >
                                <MenuItem onClick={() => {
                                    history.push('/');
                                    handleClose();
                                }}>Telefonía Móvil</MenuItem>
                                <MenuItem onClick={() => {
				    history.push('/internet-movil');
                                    handleClose();
                                }}>Internet Móvil</MenuItem>
                                <MenuItem onClick={() => {
				    history.push('/internet-fijo');
                                    handleClose();
                                }}>Internet Fijo</MenuItem>
                            </Menu>


                            <XelNavButton

                                onClick={sendToEmpresas}
                                color="inherit">
                                Empresas
                            </XelNavButton>
                            <XelNavButton
                                onClick={sendToAlianzas}
                                color="inherit">
                                Alianzas
                            </XelNavButton>
                        </Grid>
                }
                <Grid item
                      style={{width: 'fit-content', display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                    <XelRoundedNavButton
                        startIcon={<XelIconCard style={{height: 25, fill: XEL_COLORS.primary}}/>}
                        onClick={sendToPago}
                        //startIcon={<XelIconUser style={{width: 25, fill: XEL_COLORS.primary}}/>}
                        color="inherit">
                        Paga tu cuenta
                    </XelRoundedNavButton>
                    <XelRoundedNavButton
                        startIcon={<XelIconAvatar style={{height: 25, fill: XEL_COLORS.primary}}/>}
                        color="inherit"
                        onClick={login.logged ? handleMenuLogin : () => {
                            history.push('/login');
                        }}
                        style={{marginRight: 20}}
                    >
                        {login.logged ? 'Mi inXel' : 'Iniciar sesión'}
                    </XelRoundedNavButton>
                    <Menu
                        id="menu-logged"
                        anchorEl={anchorElogin}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={openLogin}
                        onClose={handleCloseLogin}
                        style={{top: 50}}
                    >
                        {
                            //<MenuItem>Mi cuenta</MenuItem>
                        }
                        <MenuItem onClick={() => {
                            Cookie.remove('xel_token');
                            sessionStorage.clear();
                            dispatch(Actions.LOG_OUT());
                            handleCloseLogin();
                            window.location.reload();
                        }}>Salir</MenuItem>
                    </Menu>
                </Grid>
            </Grid>

        </Grid>
    </Grid>);

    const MovMenu = (
        <Grid container>
            <Grid item xs={12}
                  style={{display: "flex", flexDirection: "column", alignItems: "flex-start", paddingRight: 15}}>
                <Button onClick={handleMenuMov}>
                    <div style={{display: "flex", flexDirection: "column",}}>
                        <span style={{
                            height: 5,
                            width: 25,
                            marginBottom: 3,
                            borderRadius: 5,
                            backgroundColor: XEL_COLORS.primary
                        }}/>
                        <span style={{
                            height: 5,
                            width: 25,
                            marginBottom: 3,
                            borderRadius: 5,
                            backgroundColor: XEL_COLORS.primary
                        }}/>
                        <span style={{
                            height: 5,
                            width: 25,
                            marginBottom: 3,
                            borderRadius: 5,
                            backgroundColor: XEL_COLORS.primary
                        }}/>
                    </div>
                </Button>
            </Grid>
            <Grid item xs={12}>
                {/*Global*/}
                <Menu
                    id="menu-logged"
                    anchorEl={anchorElMov}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={openMov}
                    onClose={handleCloseMov}
                    style={{top: 50}}
                >
                    {
                        !hide && <> <MenuItem onClick={handleMenu}>XelPlanes</MenuItem>
                            <MenuItem onClick={sendToEmpresas}>Empresas</MenuItem>
                            <MenuItem onClick={sendToAlianzas}>Alianzas</MenuItem>
                            <Divider/></>
                    }
                    <MenuItem onClick={login.logged ? handleMenuLogin : () => {
                        history.push('/login');
                    }}> {login.logged ? 'Mi inXel' : 'Iniciar sesión'}</MenuItem>
                    <MenuItem onClick={sendToPago}>Paga tu cuenta</MenuItem>
                </Menu>
                {/*Login*/}
                <Menu
                    id="menu-logged"
                    anchorEl={anchorElogin}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={openLogin}
                    onClose={handleCloseLogin}
                    style={{top: 50}}
                >
                    {
                        //  <MenuItem>Mi cuenta</MenuItem>
                    }
                    <MenuItem
                        onClick={() => {
                            Cookie.remove('xel_token');
                            sessionStorage.clear();
                            dispatch(Actions.LOG_OUT());
                            // eslint-disable-next-line no-undef
                            if(FB){
                                // eslint-disable-next-line no-undef
                                FB.logout(()=>{

                                });
                            }
                            handleCloseLogin();
                            window.location.reload();


                        }}>Salir</MenuItem>
                </Menu>
                {/*Personas*/}
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                    style={{top: 50}}
		>
		    <MenuItem onClick={() => {
			history.push('/');
			handleClose();
		    }}>Telefonía Móvil</MenuItem>
		    <MenuItem onClick={() => {
			history.push('/internet-movil');
			handleClose();
		    }}>Internet Móvil</MenuItem>
		    <MenuItem onClick={() => {
			history.push('/internet-fijo');
			handleClose();
		    }}>Internet Fijo</MenuItem>
                            
		</Menu>
                {/*Empresas*/}
                <Menu
                    id="menu-appbarE"
                    anchorEl={anchorElE}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={openE}
                    onClose={handleCloseE}
                    style={{top: 50}}
                >
                    <MenuItem onClick={handleMenuES}>Postpago</MenuItem>
                    <MenuItem onClick={sendToPrepago}>Prepago</MenuItem>
                </Menu>

                <Menu
                    id="menu-appbarES"
                    anchorEl={anchorElES}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={openES}
                    onClose={handleCloseES}
                    style={{top: 10, left: 100}}
                >
                    <MenuItem onClick={() => {
                        history.push('/empresas/plan');
                        handleCloseES();
                        handleCloseE();
                    }}>Solo CHIP</MenuItem>

                </Menu>
            </Grid>
        </Grid>
    )
    return (
        <div style={{ width: '100vw' }} className={classes.root} {...props}>
            <AppBar variant='elevation' position="static">
                <Toolbar id={'app-bar'} className={'Navbar-desktop'}
                         style={{
                             display: 'flex',
                             justifyContent: 'space-between',
                             flexDirection: !MaxTablet ? "row-reverse" : "row",
                             paddingRight: 0
                         }}
                         variant='dense'>
                    <div // style={{width: '150px'}}
                    >
                        <IconButton edge="start" onClick={sendToHome}
                                    aria-label="menu">
                            <img style={{height: 50}} src={"https://abs-public.s3.us-west-1.amazonaws.com/inxel/media/logos/animated-logo.gif"} alt="inXel"/>
                            {
                                <img style={{height: 50, marginLeft: 10}} src={secondaryLogo || "https://abs-public.s3.us-west-1.amazonaws.com/inxel/media/logos/static-altan-logo.png"} alt={secondaryLogo ? "alianza":"altan"}/>
                            }
                        </IconButton>
                    </div>
                    {
                        !MaxTablet ? MovMenu : DeskMenu
                    }
                </Toolbar>
            </AppBar>
        </div>
    );
}
